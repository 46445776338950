define(['siteObj'], function(siteObj) {



  const countDownTimer = () => {

    const component = {};
    component.config = {

      dayNames: {
        0: 'sunday',
        1: 'monday',
        2: 'tuesday',
        3: 'wednesday',
        4: 'thursday',
        5: 'friday',
        6: 'saturday'
      },
      timeZoneOffset: new Date().getTimezoneOffset() * 60 * 1000,
      serverTime: new Date(siteObj.features.countdown.js_servertime + ' UTC').toUTCString()
    };

    component.init = element => {
      component.theTimer = element;

      component.hasWidgetConfigs();
      component.countdown = null;
      component.widgetConfigs = component.jsonParse(component.theTimer.getAttribute('data-countdown-config'));
      component.startTime = component.config.serverTime ? component.config.serverTime : component.returnDateString();
      component.day = component.getDay();
      component.cDays = component.theTimer.querySelector('[data-countDowntTimer-digit-wrapper-days]');
      component.cWeeks = component.theTimer.querySelector('[data-countDowntTimer-digit-wrapper-weeks]');
      component.cHours = component.theTimer.querySelector('[data-countDownTimer-hours]');
      component.cMinutes = component.theTimer.querySelector('[data-countDownTimer-minutes]');
      component.cSeconds = component.theTimer.querySelector('[data-countDownTimer-seconds]');
      component.decrement = parseInt(component.widgetConfigs.decrement);
      component.deadlineType = component.widgetConfigs.dispatchTimer.toLowerCase() === 'true' ? component.getDeliveryTime() : component.widgetConfigs.countDownEndTime;
      component.deadlineValue = component.deadlineType + ' GMT';
      component.totalRemainingDate = new Date(new Date(component.deadlineValue) - new Date(component.startTime));
      component.totalRemaining = component.widgetConfigs.useLocal.toLowerCase() === 'true' ? new Date(component.totalRemainingDate).getTime() + component.config.timeZoneOffset : new Date(component.totalRemainingDate).getTime();

      component.removeTimeUnits();

      if (component.checkWidgetConfigs() === false) {
        component.tick();
      }

    };

    component.hasWidgetConfigs = () => {
      if (!component.widgetConfigs) {
        return;
      }
    };

    component.removeTimeUnits = () => {

      if (!component.remainingTime().days && component.cDays) {
        component.cDays.parentNode.removeChild(component.cDays);
      }

      if (!component.remainingTime().weeks && component.cWeeks) {
        component.cWeeks.parentNode.removeChild(component.cWeeks);
      }

    };

    component.checkWidgetConfigs = () => {

      let returnVal = false;

      if ((component.widgetConfigs.removeOnEnd.toLowerCase() === 'true' && component.remainingTime().total < 1) ||
        (component.todaysCutOffTime(component.day) === 'false' && component.widgetConfigs.dispatchTimer.toLowerCase() === 'true')) {
        component.removeTimer();
        returnVal = true;
      }

      return returnVal;
    };

    component.tick = () => {

      component.countdown = setInterval(function() {
        component.theTimer.style.visibility = 'visible'; // This is here to stop the flash of 00:00:00
        component.remainingTime().total < 1 ? component.cleanup() : component.updateTime(component.remainingTime(true));
      },
      component.decrement);

    };

    component.removeTimer = () => {
      component.theTimer.parentNode.removeChild(component.theTimer);
    };

    component.remainingTime = function(update) {

      component.totalRemaining = update ? component.totalRemaining - component.decrement : component.totalRemaining;

      return {
        'total': component.totalRemaining,
        'weeks': Math.floor(component.totalRemaining / (1000 * 60 * 60 * 24 * 7) % 52),
        'days': Math.floor(component.totalRemaining / (1000 * 60 * 60 * 24) % 7),
        'hours': Math.floor((component.totalRemaining / (1000 * 60 * 60)) % 24),
        'minutes': Math.floor((component.totalRemaining / 1000 / 60) % 60),
        'seconds': Math.floor((component.totalRemaining / 1000) % 60)
      };
    };

    component.updateTime = function(remainingTime) {

      for (let key in remainingTime) {
        if (remainingTime.hasOwnProperty(key)) {

          let timeUnits = remainingTime[key].toString().length > 1 ? remainingTime[key].toString() : '0' + remainingTime[key].toString();

          component.renderClock(key, timeUnits);
        }
      }
    };

    component.getDeliveryTime = () => {

      let today = new Date();
      let todaysCutOffTime = component.todaysCutOffTime(component.day);

      if (todaysCutOffTime == '24') {
        today.setDate(today.getDate() + 1);
        todaysCutOffTime = '00';
      }
      today = today.toUTCString();

      let deliveryCutOffTime = today.replace(/(\d+):(\d+):(\d+)/g, todaysCutOffTime + ':00:00'); // Eg. match: '12:01:53

      return deliveryCutOffTime.substr(0, deliveryCutOffTime.indexOf(deliveryCutOffTime.match('GMT|UTC')) - 1);

    };

    component.todaysCutOffTime = function(day) {
      let cutOffTime = component.widgetConfigs.weekTimes[day];

      if (cutOffTime && (cutOffTime !== 'default' && cutOffTime !== 'false')) {
        return component.timeArray(cutOffTime)[0]; // TODO:for now only use hours add minutes later
      } else if (cutOffTime === 'default' || !cutOffTime) {
        return component.widgetConfigs.defaultNextDayDeliveryCutoff;
      }

      return cutOffTime;
    };

    component.timeArray = function(cutOffTime) {

      let timeArray = [];

      if (cutOffTime.indexOf(':') === -1) {
        timeArray[0] = cutOffTime.substr(0, 2);
        timeArray[1] = '00';
      } else {
        timeArray[0] = cutOffTime.substr(0, 2);
        timeArray[1] = cutOffTime.substr(3, 2);
      }
      return timeArray;
    };

    component.getDay = () => {

      return component.config.dayNames[(new Date(component.startTime).getDay())];
    };

    component.renderClock = function(key, timeValueString) {

      let unitA = timeValueString.substr(0, 1);
      let unitB = timeValueString.substr(1, 1);

      let units = component.theTimer.querySelectorAll(`[data-countDownTimer-${key}] [data-countDownTimer-timeUnit]`);

      if (key !== 'total' && units.length) {

        if (units[0].textContent !== unitA) {
          units[0].textContent = unitA;
        }

        if (units[1].textContent !== unitB) {
          units[1].textContent = unitB;
        }
      }
    };

    component.cleanup = () => {

      clearInterval(component.countdown);

      // remove if set in config
      if (component.widgetConfigs.removeOnEnd.toLowerCase() === 'true') {
        component.removeTimer();
      }

      let resetClock = component.theTimer.querySelectorAll('.time_unit');
      for (let i = 0, l = resetClock.length; i < l; i++) {
        resetClock[i].textContent = 0;
      }
    };

    component.returnDateString = () => {

      let dateString = new Date().toUTCString();
      dateString.substr(0, dateString.indexOf('GMT') - 1);
      return dateString;
    };

    component.jsonParse = function(aString) {
      let returnJson;

      try {
        returnJson = JSON.parse(aString);
      } catch (e) {
        returnJson = false;
      }
      return returnJson;
    };

    return component;
  };

  return countDownTimer;

});
